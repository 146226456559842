import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTRedaction setMp_imp:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTRedaction getMp_imp]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy setMp_impl:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTProxy getMp_impl]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings setMp_impl:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWebPageSettings getMp_impl]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings setMp_impl:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTOCSettings getMp_impl]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTextExtractorStyle setMp_style:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTextExtractorStyle getMp_style]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWord setMp_word:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTWord getMp_word]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTextExtractorLine setMp_line:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTextExtractorLine getMp_line]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTextExtractorLine initWithImpl:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTConvert ToEpub:out_path:html_options:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTConvert ToTiff:out_path:options:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTConvert ToXodWithMonitor:options:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTCallback AnnotBitmapProc:buffer:width:height:stride:page_num:annot_index:annot_key:x_in_page:y_in_page:x_offset:y_offset:remaining_tiles:sequence_number:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTCallback StaticAnnotBitmapProc:operation_type:buffer:width:height:stride:page_num:annot_index:annot_key:x_in_page:y_in_page:x_offset:y_offset:remaining_tiles:sequence_number:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptxps_op_off</div>
<div class="difference"><span class="status added">Added</span> e_ptxps_op_on</div>
<div class="difference"><span class="status added">Added</span> e_ptcps_op_pdfx_on</div>
<div class="difference"><span class="status added">Added</span> PTXPSOverprintPreviewMode</div>
<div class="difference"><span class="status added">Added</span> -[PTMappedFile isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTObj isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTColorPt isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTFileSpec isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnot isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> PTDiffOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions GetBlendMode]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions SetBlendMode:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions GetColorA]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions SetColorA:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions GetColorB]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions SetColorB:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions init]</div>
<div class="difference"><span class="status added">Added</span> -[PTAction isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTBookmark isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert ToEpub:out_path:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert ToTiff:out_path:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert ToXodWithMonitor:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert ToXodWithMonitorAndOptions:options:]</div>
<div class="difference"><span class="status added">Added</span> -[PTXPSOutputCommonOptions GenerateURLLinks:]</div>
<div class="difference"><span class="status added">Added</span> -[PTXPSOutputCommonOptions SetOverprint:]</div>
<div class="difference"><span class="status added">Added</span> -[PTXPSOutputCommonOptions setM_obj:]</div>
<div class="difference"><span class="status added">Added</span> -[PTXPSOutputCommonOptions getM_obj]</div>
<div class="difference"><span class="status added">Added</span> -[PTXODOutputOptions UseSilverlightFlashCompatible:]</div>
<div class="difference"><span class="status added">Added</span> -[PTHTMLOutputOptions SetReportFile:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSVGOutputOptions SetOverprint:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCallback AnnotBitmapProc:buffer:width:height:stride:page_num:annot_index:annot_key:x_in_page:y_in_page:x_offset:y_offset:remaining_tiles:sequence_number:x_page_size:y_page_size:]</div>
<div class="difference"><span class="status added">Added</span> +[PTCallback StaticAnnotBitmapProc:operation_type:buffer:width:height:stride:page_num:annot_index:annot_key:x_in_page:y_in_page:x_offset:y_offset:remaining_tiles:sequence_number:x_page_size:y_page_size:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOptimizerSettings RemoveCustomEntries:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOptimizerSettings setM_remove_custom:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOptimizerSettings getM_remove_custom]</div>
<div class="difference"><span class="status added">Added</span> -[PTPageLabel isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc AppendVisualDiff:p2:opts:]</div>
<div class="difference"><span class="status added">Added</span> +[PTPDFNet IsInitialized]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextExtractorStyle isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWord isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextExtractorLine isEqualTo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextExtractorLine init]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTTiffOutputOptions SetBox:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetBox:(int)type</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetBox:(PTBox)type</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTTiffOutputOptions SetRotate:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetRotate:(int)rotation</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetRotate:(PTRotate)rotation</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTAppearance setRedactedContentColor:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)setRedactedContentColor:(SWIGTYPE_ColorPt *)value</td></tr>
<tr><th>To</th><td class="declaration">- (void)setRedactedContentColor:(PTColorPt *)value</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTAppearance getRedactedContentColor]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (SWIGTYPE_ColorPt *)getRedactedContentColor</td></tr>
<tr><th>To</th><td class="declaration">- (PTColorPt *)getRedactedContentColor</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl OpenUrlAsync:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetZoomLimitMode]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetZoomMinimumLimit]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetZoomMaximumLimit]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetThumbAsync:completion:]</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrlDidCloseDocNotification</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrlDelegate pdfViewCtrlDidCloseDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrlDidCloseDoc:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.overlayView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, retain) UIView *overlayView</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, retain, nonnull) UIView *overlayView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.toolOverlayView</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, retain) UIView *toolOverlayView</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, retain, nonnull) UIView *toolOverlayView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.delegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak) id&lt;PTPDFViewCtrlDelegate&gt; delegate</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTPDFViewCtrlDelegate&gt; delegate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrl.toolDelegate</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak) id&lt;PTPDFViewCtrlToolDelegate&gt; toolDelegate</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, weak, nullable) id&lt;PTPDFViewCtrlToolDelegate&gt; toolDelegate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl SetDoc:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetDoc:(PTPDFDoc *)doc</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetDoc:(nonnull PTPDFDoc *)doc</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetDoc]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFDoc *)GetDoc</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTPDFDoc *)GetDoc</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl OpenUrlAsync:WithPDFPassword:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)OpenUrlAsync:(NSString *)url WithPDFPassword:(NSString *)password</td></tr>
<tr><th>To</th><td class="declaration">- (void)OpenUrlAsync:(nonnull NSString *)url WithPDFPassword:(nullable NSString *)password</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl OpenUrlAsync:WithPDFPassword:WithCacheFile:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)OpenUrlAsync:(NSString *)url WithPDFPassword:(NSString *)password WithCacheFile:(NSString *)fullPath</td></tr>
<tr><th>To</th><td class="declaration">- (void)OpenUrlAsync:(nonnull NSString *)url WithPDFPassword:(nullable NSString *)password WithCacheFile:(nullable NSString *)fullPath</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl OpenUrlAsync:WithPDFPassword:WithCacheFile:WithOptions:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)OpenUrlAsync:(NSString *)url WithPDFPassword:(NSString *)password WithCacheFile:(NSString *)fullPath WithOptions:(PTHTTPRequestOptions *)options</td></tr>
<tr><th>To</th><td class="declaration">- (void)OpenUrlAsync:(nonnull NSString *)url WithPDFPassword:(nullable NSString *)password WithCacheFile:(nullable NSString *)fullPath WithOptions:(nullable PTHTTPRequestOptions *)options</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetVisiblePages]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (NSMutableArray&lt;NSNumber *&gt; *)GetVisiblePages</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull NSMutableArray&lt;NSNumber *&gt; *)GetVisiblePages</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ShowRect:rect:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)ShowRect:(int)page_num rect:(PTPDFRect *)rect</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)ShowRect:(int)page_num rect:(nonnull PTPDFRect *)rect</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl SetColorPostProcessColors:black_color:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetColorPostProcessColors:(UIColor *)white_color black_color:(UIColor *)black_color</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetColorPostProcessColors:(nonnull UIColor *)white_color black_color:(nonnull UIColor *)black_color</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl SetOCGContext:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)SetOCGContext:(PTContext *)ctx</td></tr>
<tr><th>To</th><td class="declaration">- (void)SetOCGContext:(nonnull PTContext *)ctx</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetOCGContext]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTContext *)GetOCGContext</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTContext *)GetOCGContext</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl FindText:MatchCase:MatchWholeWord:SearchUp:RegExp:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)FindText:(NSString *)searchString MatchCase:(BOOL)matchCase MatchWholeWord:(BOOL)matchWholeWord SearchUp:(BOOL)searchUp RegExp:(BOOL)regExp</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)FindText:(nonnull NSString *)searchString MatchCase:(BOOL)matchCase MatchWholeWord:(BOOL)matchWholeWord SearchUp:(BOOL)searchUp RegExp:(BOOL)regExp</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl highlightSelection:withColor:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)highlightSelection:(PTSelection *)selection withColor:(UIColor *)color</td></tr>
<tr><th>To</th><td class="declaration">- (void)highlightSelection:(nonnull PTSelection *)selection withColor:(nonnull UIColor *)color</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl highlightSelections:withColor:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)highlightSelections:(NSArray&lt;PTSelection *&gt; *)selections withColor:(UIColor *)color</td></tr>
<tr><th>To</th><td class="declaration">- (void)highlightSelections:(nonnull NSArray&lt;PTSelection *&gt; *)selections withColor:(nonnull UIColor *)color</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetSelection:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTSelection *)GetSelection:(int)pagenum</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTSelection *)GetSelection:(int)pagenum</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl SelectWithHighlights:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)SelectWithHighlights:(PTHighlights *)highlights</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)SelectWithHighlights:(nonnull PTHighlights *)highlights</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl SelectWithSelection:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)SelectWithSelection:(PTSelection *)select</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)SelectWithSelection:(nonnull PTSelection *)select</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetScreenRectForAnnot:page_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFRect *)GetScreenRectForAnnot:(PTAnnot *)annot page_num:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTPDFRect *)GetScreenRectForAnnot:(nonnull PTAnnot *)annot page_num:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetAnnotationAt:y:distanceThreshold:minimumLineWeight:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTAnnot *)GetAnnotationAt:(int)x y:(int)y distanceThreshold:(double)distanceThreshold minimumLineWeight:(double)minimumLineWeight</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTAnnot *)GetAnnotationAt:(int)x y:(int)y distanceThreshold:(double)distanceThreshold minimumLineWeight:(double)minimumLineWeight</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetLinkAt:y:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTLinkInfo *)GetLinkAt:(int)x y:(int)y</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTLinkInfo *)GetLinkAt:(int)x y:(int)y</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl handleTap:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)handleTap:(UITapGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (void)handleTap:(nonnull UITapGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl handleLongPress:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)handleLongPress:(UILongPressGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (void)handleLongPress:(nonnull UILongPressGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ConvScreenPtToCanvasPt:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFPoint *)ConvScreenPtToCanvasPt:(PTPDFPoint *)pt</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFPoint *)ConvScreenPtToCanvasPt:(nonnull PTPDFPoint *)pt</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ConvCanvasPtToScreenPt:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFPoint *)ConvCanvasPtToScreenPt:(PTPDFPoint *)pt</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFPoint *)ConvCanvasPtToScreenPt:(nonnull PTPDFPoint *)pt</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ConvCanvasPtToPagePt:page_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFPoint *)ConvCanvasPtToPagePt:(PTPDFPoint *)pt page_num:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFPoint *)ConvCanvasPtToPagePt:(nonnull PTPDFPoint *)pt page_num:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ConvPagePtToCanvasPt:page_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFPoint *)ConvPagePtToCanvasPt:(PTPDFPoint *)pt page_num:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFPoint *)ConvPagePtToCanvasPt:(nonnull PTPDFPoint *)pt page_num:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ConvScreenPtToPagePt:page_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFPoint *)ConvScreenPtToPagePt:(PTPDFPoint *)pt page_num:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFPoint *)ConvScreenPtToPagePt:(nonnull PTPDFPoint *)pt page_num:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ConvPagePtToScreenPt:page_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFPoint *)ConvPagePtToScreenPt:(PTPDFPoint *)pt page_num:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFPoint *)ConvPagePtToScreenPt:(nonnull PTPDFPoint *)pt page_num:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl PDFRectPage2CGRectScreen:PageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (CGRect)PDFRectPage2CGRectScreen:(PTPDFRect *)pdfRect PageNumber:(int)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (CGRect)PDFRectPage2CGRectScreen:(nonnull PTPDFRect *)pdfRect PageNumber:(int)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl CGRectScreen2PDFRectPage:PageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTPDFRect *)CGRectScreen2PDFRectPage:(CGRect)cgRect PageNumber:(int)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTPDFRect *)CGRectScreen2PDFRectPage:(CGRect)cgRect PageNumber:(int)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl GetDeviceTransform:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (PTMatrix2D *)GetDeviceTransform:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (nullable PTMatrix2D *)GetDeviceTransform:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl UpdateWithAnnot:page_num:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)UpdateWithAnnot:(PTAnnot *)annot page_num:(int)page_num</td></tr>
<tr><th>To</th><td class="declaration">- (void)UpdateWithAnnot:(nonnull PTAnnot *)annot page_num:(int)page_num</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl UpdateWithRect:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)UpdateWithRect:(PTPDFRect *)update</td></tr>
<tr><th>To</th><td class="declaration">- (void)UpdateWithRect:(nonnull PTPDFRect *)update</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl UpdateWithField:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)UpdateWithField:(PTField *)field</td></tr>
<tr><th>To</th><td class="declaration">- (void)UpdateWithField:(nonnull PTField *)field</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl RefreshAndUpdate:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)RefreshAndUpdate:(PTViewChangeCollection *)view_change</td></tr>
<tr><th>To</th><td class="declaration">- (void)RefreshAndUpdate:(nonnull PTViewChangeCollection *)view_change</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTPDFViewCtrl SetViewerCache:max_cache_size:on_disk:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)SetViewerCache:(PTSDFDoc *)document max_cache_size:(unsigned long)max_cache_size on_disk:(BOOL)on_disk</td></tr>
<tr><th>To</th><td class="declaration">+ (void)SetViewerCache:(nonnull PTSDFDoc *)document max_cache_size:(unsigned long)max_cache_size on_disk:(BOOL)on_disk</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl keyboardWillShow:rectToNotOverlapWith:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)keyboardWillShow:(NSNotification *)notification rectToNotOverlapWith:(CGRect)rect</td></tr>
<tr><th>To</th><td class="declaration">- (void)keyboardWillShow:(nonnull NSNotification *)notification rectToNotOverlapWith:(CGRect)rect</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl keyboardWillShow:rectToNotOverlapWith:topEdge:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)keyboardWillShow:(NSNotification *)notification rectToNotOverlapWith:(CGRect)rect topEdge:(CGFloat)topEdge</td></tr>
<tr><th>To</th><td class="declaration">- (void)keyboardWillShow:(nonnull NSNotification *)notification rectToNotOverlapWith:(CGRect)rect topEdge:(CGFloat)topEdge</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl keyboardWillHide:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)keyboardWillHide:(NSNotification *)notification</td></tr>
<tr><th>To</th><td class="declaration">- (void)keyboardWillHide:(nonnull NSNotification *)notification</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ExecuteAction:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)ExecuteAction:(PTAction *)action</td></tr>
<tr><th>To</th><td class="declaration">- (void)ExecuteAction:(nonnull PTAction *)action</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrl ExecuteActionWithActionParameter:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)ExecuteActionWithActionParameter:(PTActionParameter *)action_param</td></tr>
<tr><th>To</th><td class="declaration">- (void)ExecuteActionWithActionParameter:(nonnull PTActionParameter *)action_param</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrlPageDidChangeNotification</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">const NSNotificationName PTPDFViewCtrlPageDidChangeNotification</td></tr>
<tr><th>To</th><td class="declaration">const NSNotificationName _Nonnull PTPDFViewCtrlPageDidChangeNotification</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrlPreviousPageNumberUserInfoKey</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">NSString *const PTPDFViewCtrlPreviousPageNumberUserInfoKey</td></tr>
<tr><th>To</th><td class="declaration">NSString *const _Nonnull PTPDFViewCtrlPreviousPageNumberUserInfoKey</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTPDFViewCtrlCurrentPageNumberUserInfoKey</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">NSString *const PTPDFViewCtrlCurrentPageNumberUserInfoKey</td></tr>
<tr><th>To</th><td class="declaration">NSString *const _Nonnull PTPDFViewCtrlCurrentPageNumberUserInfoKey</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrlOnRenderFinished:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrlOnRenderFinished:(PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrlOnRenderFinished:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrlOnLayoutChanged:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrlOnLayoutChanged:(PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrlOnLayoutChanged:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pageNumberChangedFrom:To:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pageNumberChangedFrom:(int)oldPageNumber To:(int)newPageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pageNumberChangedFrom:(int)oldPageNumber To:(int)newPageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:onSetDoc:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl onSetDoc:(PTPDFDoc *)doc</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl onSetDoc:(nonnull PTPDFDoc *)doc</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrlTextSearchStart:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrlTextSearchStart:(PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrlTextSearchStart:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:textSearchResult:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl textSearchResult:(PTSelection *)selection</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl textSearchResult:(nonnull PTSelection *)selection</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:downloadEventType:pageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl downloadEventType:(PTDownloadedType)type pageNumber:(int)pageNum</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl downloadEventType:(PTDownloadedType)type pageNumber:(int)pageNum</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:downloadError:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl downloadError:(NSException *)exception</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl downloadError:(nonnull NSException *)exception</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:gotThumbAsync:thumbImage:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl gotThumbAsync:(int)page_num thumbImage:(UIImage *)image</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl gotThumbAsync:(int)page_num thumbImage:(nullable UIImage *)image</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidScroll:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScroll:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScroll:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewWillBeginDragging:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDragging:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDragging:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidEndDragging:willDecelerate:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDragging:(UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDragging:(nonnull UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewShouldScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewShouldScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewShouldScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewWillBeginDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidEndDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewWillBeginZooming:withView:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginZooming:(UIScrollView *)scrollView withView:(UIView *)view</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidEndZooming:withView:atScale:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndZooming:(UIScrollView *)scrollView withView:(UIView *)view atScale:(float)scale</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view atScale:(float)scale</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidZoom:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidZoom:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidZoom:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDidEndScrollingAnimation:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndScrollingAnimation:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndScrollingAnimation:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewTap:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewTap:(UITapGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewTap:(nonnull UITapGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewDoubleTap:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDoubleTap:(UITapGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDoubleTap:(nonnull UITapGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:pdfScrollViewLongPress:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewLongPress:(UILongPressGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewLongPress:(nonnull UILongPressGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidScroll:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScroll:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScroll:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewWillBeginDragging:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDragging:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDragging:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidEndDragging:willDecelerate:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDragging:(UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDragging:(nonnull UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewShouldScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewShouldScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewShouldScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewWillBeginDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidEndDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewWillBeginZooming:withView:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginZooming:(UIScrollView *)scrollView withView:(UIView *)view</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidEndZooming:withView:atScale:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndZooming:(UIScrollView *)scrollView withView:(UIView *)view atScale:(float)scale</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view atScale:(CGFloat)scale</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidZoom:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidZoom:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidZoom:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:outerScrollViewDidEndScrollingAnimation:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndScrollingAnimation:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndScrollingAnimation:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlDelegate pdfViewCtrl:javascriptCallback:json:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl javascriptCallback:(const char *)event_type json:(const char *)json</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl javascriptCallback:(nullable const char *)event_type json:(nullable const char *)json</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:onTouchesBegan:withEvent:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl onTouchesBegan:(NSSet&lt;UITouch *&gt; *)touches withEvent:(UIEvent *)event</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl onTouchesBegan:(nonnull NSSet&lt;UITouch *&gt; *)touches withEvent:(nullable UIEvent *)event</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:onTouchesMoved:withEvent:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl onTouchesMoved:(NSSet&lt;UITouch *&gt; *)touches withEvent:(UIEvent *)event</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl onTouchesMoved:(nonnull NSSet&lt;UITouch *&gt; *)touches withEvent:(nullable UIEvent *)event</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:onTouchesEnded:withEvent:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl onTouchesEnded:(NSSet&lt;UITouch *&gt; *)touches withEvent:(UIEvent *)event</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl onTouchesEnded:(nonnull NSSet&lt;UITouch *&gt; *)touches withEvent:(nullable UIEvent *)event</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:onTouchesCancelled:withEvent:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl onTouchesCancelled:(NSSet&lt;UITouch *&gt; *)touches withEvent:(UIEvent *)event</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl onTouchesCancelled:(nonnull NSSet&lt;UITouch *&gt; *)touches withEvent:(nullable UIEvent *)event</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:handleLongPress:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl handleLongPress:(UILongPressGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl handleLongPress:(nonnull UILongPressGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:handleTap:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl handleTap:(UITapGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl handleTap:(nonnull UITapGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:handleDoubleTap:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl handleDoubleTap:(UITapGestureRecognizer *)gestureRecognizer</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl handleDoubleTap:(nonnull UITapGestureRecognizer *)gestureRecognizer</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:touchesShouldCancelInContentView:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl touchesShouldCancelInContentView:(UIView *)view</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl touchesShouldCancelInContentView:(nonnull UIView *)view</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:touchesShouldBegin:withEvent:inContentView:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl touchesShouldBegin:(NSSet&lt;UITouch *&gt; *)touches withEvent:(UIEvent *)event inContentView:(UIView *)view</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl touchesShouldBegin:(nonnull NSSet&lt;UITouch *&gt; *)touches withEvent:(nullable UIEvent *)event inContentView:(nonnull UIView *)view</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidScroll:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScroll:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScroll:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewWillBeginDragging:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDragging:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDragging:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidEndDragging:willDecelerate:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDragging:(UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDragging:(nonnull UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewShouldScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewShouldScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewShouldScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewWillBeginDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidEndDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewWillBeginZooming:withView:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginZooming:(UIScrollView *)scrollView withView:(UIView *)view</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewWillBeginZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidEndZooming:withView:atScale:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndZooming:(UIScrollView *)scrollView withView:(UIView *)view atScale:(float)scale</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view atScale:(float)scale</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidZoom:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidZoom:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidZoom:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pdfScrollViewDidEndScrollingAnimation:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndScrollingAnimation:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pdfScrollViewDidEndScrollingAnimation:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidScroll:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScroll:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScroll:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewWillBeginDragging:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDragging:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDragging:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidEndDragging:willDecelerate:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDragging:(UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDragging:(nonnull UIScrollView *)scrollView willDecelerate:(BOOL)decelerate</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewShouldScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewShouldScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewShouldScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidScrollToTop:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScrollToTop:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidScrollToTop:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewWillBeginDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidEndDecelerating:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDecelerating:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndDecelerating:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewWillBeginZooming:withView:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginZooming:(UIScrollView *)scrollView withView:(UIView *)view</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewWillBeginZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidEndZooming:withView:atScale:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndZooming:(UIScrollView *)scrollView withView:(UIView *)view atScale:(float)scale</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndZooming:(nonnull UIScrollView *)scrollView withView:(nullable UIView *)view atScale:(float)scale</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidZoom:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidZoom:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidZoom:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:outerScrollViewDidEndScrollingAnimation:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndScrollingAnimation:(UIScrollView *)scrollView</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl outerScrollViewDidEndScrollingAnimation:(nonnull UIScrollView *)scrollView</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrlOnLayoutChanged:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrlOnLayoutChanged:(PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrlOnLayoutChanged:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:pageNumberChangedFrom:To:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl pageNumberChangedFrom:(int)oldPageNumber To:(int)newPageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl pageNumberChangedFrom:(int)oldPageNumber To:(int)newPageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrlOnSetDoc:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrlOnSetDoc:(PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrlOnSetDoc:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrlOnRenderFinished:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrlOnRenderFinished:(PTPDFViewCtrl *)pdfViewCtrl</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrlOnRenderFinished:(nonnull PTPDFViewCtrl *)pdfViewCtrl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFViewCtrlToolDelegate pdfViewCtrl:javascriptCallback:json:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)pdfViewCtrl:(PTPDFViewCtrl *)pdfViewCtrl javascriptCallback:(const char *)event_type json:(const char *)json</td></tr>
<tr><th>To</th><td class="declaration">- (void)pdfViewCtrl:(nonnull PTPDFViewCtrl *)pdfViewCtrl javascriptCallback:(nullable const char *)event_type json:(nullable const char *)json</td></tr>
</table>
<br>
</div>

</div>



<div class="headerFile">
<div class="headerName">Tools/PTAnalyticsHandlerAdapter.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTAnalyticsHandlerAdapter</div>
<table>
<tr><th></th><th>Availability</th><th>Deprecation Message</th></tr>
<tr><th>From</th><td>Available</td><td><em>none</em></td></tr>
<tr><th>To</th><td>Deprecated</td><td>Deprecated in Apryse for iOS 6.9.5. Use PTAnalyticsManager instead</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> +[PTAnalyticsHandlerAdapter getInstance]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (nonnull id)getInstance</td></tr>
<tr><th>To</th><td class="declaration">+ (nonnull instancetype)getInstance</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnalyticsManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnalyticsHandler</div>
<div class="difference"><span class="status added">Added</span> -[PTAnalyticsHandler logException:withExtraData:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnalyticsHandler sendCustomEventWithTag:]</div>
<div class="difference"><span class="status added">Added</span> PTAnalyticsManager</div>
<div class="difference"><span class="status added">Added</span> PTAnalyticsManager.defaultManager</div>
<div class="difference"><span class="status added">Added</span> PTAnalyticsManager.handlers</div>
<div class="difference"><span class="status added">Added</span> -[PTAnalyticsManager addHandler:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnalyticsManager removeHandler:]</div>
<div class="difference"><span class="status added">Added</span> PTLogException()</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTBookmarkViewController.bookmarksEditableOnCreation</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentOpening.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTDocumentOpening</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentOpening openDocumentWithURL:error:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentOpening openDocumentWithURL:password:error:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewControllerDelegate documentViewController:willShowToolbar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewControllerDelegate documentViewController:didShowToolbar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewControllerDelegate documentViewController:willHideToolbar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewControllerDelegate documentViewController:didHideToolbar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewControllerDelegate documentViewControllerDidHideNavigationBar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewControllerDelegate documentViewControllerDidShowNavigationBar:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController setDocument:error:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController setCoordinatedDocument:completionHandler:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController closeDocument]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewControllerDidOpenDocument:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:didFailToOpenDocumentWithError:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:destinationURLForDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController openDocumentWithURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController openDocumentWithURL:password:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController openDocumentWithPDFDoc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewController closeDocumentWithCompletionHandler:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTDocumentOpening, PTOverridable, PTPDFViewCtrlDelegate, PTToolManagerDelegate, UIPopoverPresentationControllerDelegate</td></tr>
<tr><th>To</th><td>PTAnnotationToolbarDelegate, PTAnnotationViewControllerDelegate, PTBookmarkViewControllerDelegate, PTCoordinatedDocumentDelegate, PTOutlineViewControllerDelegate, PTOverridable, PTPDFViewCtrlDelegate, PTReflowViewControllerDelegate, PTSettingsViewControllerDelegate, PTTextSearchViewControllerDelegate, PTThumbnailSliderViewDelegate, PTToolManagerDelegate</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTNoteEditController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTNoteEditController.readonly</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTSelectionRectContainerView setAnnot:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)setAnnot:(nonnull PTAnnot *)annot</td></tr>
<tr><th>To</th><td class="declaration">- (void)setAnnot:(nullable PTAnnot *)annot</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTSelectionRectView.annot</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nonnull) PTAnnot *annot</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nullable) PTAnnot *annot</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTabbedDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTTabbedDocumentViewController addTabWithCoordinatedDocument:selected:completionHandler:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTabbedDocumentViewController insertTabWithCoordinatedDocument:atIndex:selected:completionHandler:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTabbedDocumentViewController removeTabWithCoordinatedDocument:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTabbedDocumentViewController addTabWithURL:selected:error:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTabbedDocumentViewController insertTabWithURL:atIndex:selected:error:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController init]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController openDocumentWithURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController openDocumentWithURL:password:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController addTabWithURL:selected:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController insertTabWithURL:atIndex:selected:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTTabbedDocumentViewController</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td>PTDocumentOpening</td></tr>
<tr><th>To</th><td><em>none</em></td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTToolOptionsDelegate tool:hasEditPermissionForAnnot:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.annotationAuthor</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager hasEditPermissionForAnnot:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.annotationPermissionCheckEnabled</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:annotationAdded:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool annotationAdded:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)tool:(nonnull PTTool *)tool annotationAdded:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:annotationModified:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool annotationModified:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)tool:(nonnull PTTool *)tool annotationModified:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:annotationRemoved:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool annotationRemoved:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)tool:(nonnull PTTool *)tool annotationRemoved:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:formFieldDataModified:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool formFieldDataModified:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)tool:(nonnull PTTool *)tool formFieldDataModified:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:shouldHandleLinkAnnotation:orLinkInfo:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool shouldHandleLinkAnnotation:(nullable PTAnnot *)annotation orLinkInfo:(nullable PTLinkInfo *)linkInfo onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool shouldHandleLinkAnnotation:(nullable PTAnnot *)annotation orLinkInfo:(nullable PTLinkInfo *)linkInfo onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:shouldSelectAnnotation:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool shouldSelectAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool shouldSelectAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:didSelectAnnotation:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool didSelectAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (void)tool:(nonnull PTTool *)tool didSelectAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:shouldShowMenu:forAnnotation:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool shouldShowMenu:(nonnull UIMenuController *)menuController forAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool shouldShowMenu:(nonnull UIMenuController *)menuController forAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate tool:canCreateExtendedAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool canCreateExtendedAnnotType:(PTExtendedAnnotType)annotType</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool canCreateExtendedAnnotType:(PTExtendedAnnotType)annotType</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate tool:canEditAnnotation:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool canEditAnnotation:(nonnull PTAnnot *)annotation</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool canEditAnnotation:(nonnull PTAnnot *)annotation</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate tool:canEditExtendedAnnotType:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool canEditExtendedAnnotType:(PTExtendedAnnotType)annotType</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool canEditExtendedAnnotType:(PTExtendedAnnotType)annotType</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate isTextSelectionEnabledForTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)isTextSelectionEnabledForTool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)isTextSelectionEnabledForTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate isFormFillingEnabledForTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)isFormFillingEnabledForTool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)isFormFillingEnabledForTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate isLinkFollowingEnabledForTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)isLinkFollowingEnabledForTool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)isLinkFollowingEnabledForTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolOptionsDelegate isEraserEnabledForTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)isEraserEnabledForTool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)isEraserEnabledForTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTToolManager.tool</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, strong, nullable) UIView&lt;PTToolSwitching&gt; *tool</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, strong, nullable) PTTool *tool</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolManager changeTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull UIView&lt;PTToolSwitching&gt; *)changeTool:(nonnull Class)toolType</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull PTTool *)changeTool:(nonnull Class)toolType</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolManagerDelegate toolManager:shouldSwitchToTool:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)toolManager:(nonnull PTToolManager *)toolManager shouldSwitchToTool:(nonnull UIView&lt;PTToolSwitching&gt; *)tool</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)toolManager:(nonnull PTToolManager *)toolManager shouldSwitchToTool:(nonnull PTTool *)tool</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsUtil.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> #def PTLocalizedString</div>
<div class="difference"><span class="status removed">Removed</span> #def PTLocalizedStringFromTable</div>
<div class="difference"><span class="status removed">Removed</span> #def PTLocalizedStringFromTableInBundle</div>
<div class="difference"><span class="status removed">Removed</span> #def PTLocalizedStringWithDefaultValue</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTLocalizedString()</div>
<div class="difference"><span class="status added">Added</span> PTLocalizedStringFromTable()</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}